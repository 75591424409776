import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table } from "antd";
import { useEffect } from "react";
import MediaQuery from "react-responsive";
import Categories from "../../../constants/categories";
import { AdminProductDto } from "../../../models/AdminProductDto";
import ApiService from "../../../services/Api/api-service";
import { useTable } from "../../../stores/Store";
import './productDesigner.css';

const ProductDesignerPageFilling = () => {
    let api = new ApiService();
    const [state, actions] = useTable();

    const [form] = Form.useForm();

    // useEffect(() => {
    //   getBlockedProducts();     
    // }, []);    

    

    // const getBlockedProducts = async () => {        
    //   await api
    //   .getBlockedProducts()
    //   .then((res) => {
    //     actions.setBlockedProducts(res);        
    //     console.log(res)
    //   });       
    // }

    async function changeProduct(product: any) {        
        let newProduct: AdminProductDto = {
            id: 0,
            asin: product.productAsin,
            name: product.productName,
            buy: product.currentPrice,
            averagePrice: product.oldPrice,
            rating: product.rating,
            category: product.category,
            description: product.description ?? "",
            imageUrl: product.imageUrl,
            storeLink: product.productUrl,
            daysInRedis: product.daysInRedis ?? 0,
            hoursInRedis: product.hoursInRedis ?? 0,
            minutesInRedis: product.minutesInRedis ?? 1
        };
        
        var response = await api.CreateProductFromAdmin(newProduct);    
      }

      const options = Object.values(Categories).slice(1).map(category => ({
        value: category,
        label: category,
      }));

    return (
      <>
        <Row justify="center" style={{margin: '120px 0px'}}>
          <Col><div className="about-title text-color-align-line-height">Product Designer</div></Col>
        </Row>
        <Row gutter={12} align="middle" justify="space-around">
            <Form
              name="basic"
              form={form}
              labelAlign="left"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              style={{
                width: "1000px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              onFinish={changeProduct}
            >
              <Row style={{display: "flex", flexDirection:"column", marginBottom: '10px'}}>
              <Form.Item
                className="cardLabelText"
                label="Product Name"
                name="productName"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input name!" },
              {max: 100, message: "The name of the product cannot be more than 100 characters!"}]}
              >
                <Input max={100}/>
              </Form.Item> 
              <Row style={{justifyContent:'space-evenly'}}>
              <Form.Item
                className="cardLabelText"
                label="Asin"
                name="productAsin"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input Asin!" }]}
              >
                <Input width={"300px"}/>
              </Form.Item>
              <Form.Item
                className="cardLabelText"
                label="Category"
                name="category"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please choose category!" }]}
              >
                <Select 
                style={{width:'300px'}}
                options={options}
                />
              </Form.Item>
              </Row>
              <Row justify={'space-evenly'}>
              <Form.Item
                className="cardLabelText"
                label="Current price"
                name="currentPrice"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input current price!" }]}
              >
                <InputNumber style={{width:"250px"}} min={0}/>
              </Form.Item>
              <Form.Item
                className="cardLabelText"
                label="Old price"
                name="oldPrice"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input old price!" }]}
              >
                <InputNumber  style={{width:"250px"}} min={0}/>
              </Form.Item>
              <Form.Item
                className="cardLabelText"
                label="Rating"
                name="rating"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input rating!" }]}
              >
                <InputNumber  style={{width:"250px"}} min={0} max={50 }/>
              </Form.Item>
              </Row>                
              <Form.Item
                className="cardLabelText"
                label="Description"
                name="description"
                labelCol={{ span: 24 }}
              >
                <Input/>
              </Form.Item>              
              <Form.Item
                className="cardLabelText"
                label="Image url"
                name="imageUrl"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input image url!" }]}
              >
                <Input />
              </Form.Item> 
              <Form.Item
                className="cardLabelText"
                label="Product url"
                name="productUrl"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true, message: "Please input product url!" }]}
              >
                <Input />
              </Form.Item>
              <Row justify={'center'}>
                <p  className="cardLabelText" style={{marginRight:"25px"}}>Time on site:</p>
                <Form.Item
                  className="cardLabelText"
                  label="Days"
                  name="daysInRedis"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber min={0} defaultValue={0}/>
                </Form.Item> 
                <Form.Item
                  className="cardLabelText"
                  label="Hours"
                  name="hoursInRedis"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber max={24} min={0} defaultValue={0}/>
                </Form.Item> 
                <Form.Item
                  className="cardLabelText"
                  label="Minutes"
                  name="minutesInRedis"
                  labelCol={{ span: 24 }}                
                >
                  <InputNumber max={60} min={0} defaultValue={0}/>
                </Form.Item> 
              </Row>           
              </Row>
              <Row style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width:"200px"}}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>                
              </Row>              
            </Form>
          </Row>      
      </>
    );
  };
  
  export default ProductDesignerPageFilling;