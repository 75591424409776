import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Form, Layout, message, Row, Spin, Upload, UploadProps } from "antd";
import { ButtonProps } from "antd/es/button";
import { useState } from "react";
import ApiService from "../../../services/Api/api-service";

const FileUploader = () => {
  const [form] = Form.useForm();
  const [btnActive, setBtnActive] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  let api = new ApiService();

  const props: UploadProps = {
    name: "file",
    multiple: true,
    //accept: ".xlsx",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
    if (status !== 'uploading') {
      console.log("Upl", info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
      setBtnActive(false);
    },
  };

  const propsButton: ButtonProps = {
    name: "UploadButton",
    htmlType: "submit",
    type: "primary",
    shape: "round",
    disabled: btnActive,
    icon: <DownloadOutlined />,
    size: "large",
    async onClick() {  
      setLoader(true)    
      const data = new FormData();
      var values = form.getFieldValue("dragger");      
      for (var i = 0; i < values.length; i++) {
        data.append("files", values[i].originFileObj);
      }
      await api.uploadProducts(data);
      form.resetFields();
      setLoader(false)            
      setBtnActive(true); 
    },
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Layout>
      <Layout.Content style={{backgroundColor: "white"}}>
        <Form form={form}>
          <Form.Item>
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          {loader === true ?
            <Row justify={"center"} style={{margin:"15px 15px 15px 15px"}}>
              <Spin/>
            </Row>
            : <></>
          }
          <Form.Item>
            <Row justify="center">
              <Button {...propsButton}>Upload</Button>
            </Row>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export default FileUploader;
