import axios, { Canceler } from "axios";
import { createBrowserHistory } from 'history';
import BASE_URL from "../../config";
import AuthStore from "../../stores/auth-store";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const history = createBrowserHistory();
let cancel: Canceler;

interface HttpResponse {
  status: number;
  headers: any;
  data: any;
}

axios.interceptors.request.use(
  (config) => {
    const token = AuthStore.getToken() as string;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    //config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => res,
  err => {
    if (err.response?.status === 401) {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      });
      source.cancel();
      AuthStore.removeToken();
      const str = window.location.pathname
      if (str !== "/superAdmin") {
        localStorage.setItem('pathName', str);
      }
      history.push("/superAdmin");
      window.location.reload();

    }
    if (err.response?.status === 403) {
      history.push("/superAdmin");
      window.location.reload();
    }
    return Promise.reject(err);
  }
);

const get = async (
  url: string,
  data?: any,
  paramsSerializer?: any
): Promise<HttpResponse> => {
  const response = await axios.get(BASE_URL + url, {
    params: data,
    paramsSerializer: paramsSerializer,
  });
  return response;
};

const postJson = async (url: string, data?: any) => {
  const response = await axios.post(BASE_URL + url, data, {
    headers: {
      "Accept": "application/json",
      "Content-Type": 'application/json',
    },
  });
  return response;
};

const postFile = async (url: string, data?: any) => {
  const response = await axios.post(BASE_URL + url, data, {
    headers: {
      "Accept": "application/json",
      'Content-Type': 'multipart/form-data'
    },
  });
  return response;
};

const put = async (url: string, data?: any) => {
  const response = await axios
    .put(BASE_URL + url, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const remove = async (
  url: string,
  data?: any,
  options: any = {}
): Promise<HttpResponse> => {
  const response = await axios.delete(BASE_URL + url, {
    ...options,
    params: data,
  });
  return response;
};

export default { get, postJson, postFile, put, remove };
