import { Button } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Categories from '../../constants/categories';
import DealTypes from '../../constants/dealTypes';
import Pages from '../../constants/pages';
import { useTable } from '../../stores/Store';
import "./header.css";

type RefreshButtonProps = {
    path: string
    text?: string
    children?: any
    className: string
}

const RefreshButton: React.FC<RefreshButtonProps> = ({
    path,
    text,
    children,
    className
}) => {
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const [state, actions] = useTable();

  const handleRefresh = () => {
    navigate(path);

    if(currentPath.includes(Pages.Home)){
      window.location.reload();

      actions.setCategory(Categories.All)
      actions.setDepartment("")
      actions.setSubdepartment("")
      actions.setSelectedMenuItem(Categories.All)
      actions.setChosenSearchOption({value: Categories.All, label: Categories.All})
      actions.setPage(1)
      actions.setSearchedData("")
      actions.setDealType(DealTypes.NewestDeals)
    }    
  };

  let inputPath = path?.split('/').at(1);  

  if (currentPath.includes(inputPath!)) {
      className += ' header-button-active'
  }  

  return (
    <Button className={className} type="link" onClick={handleRefresh}>
      {text || children}
    </Button>
  );
};

export default RefreshButton;