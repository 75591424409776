import { ArrowDownOutlined } from "@ant-design/icons";
import { Button, Col, Rate, Row } from "antd";
import { FC } from "react";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import Pages from "../../../../constants/pages";
import { ProductDto } from "../../../../models/ProductDto";
import "./advertising.css";

type AdvertisingProductProps = {
  product: ProductDto;
};

const AdvertisingProduct: FC<AdvertisingProductProps> = ({ product }) => {
  let navigate = useNavigate();

  const navigateToProduct = (productId: number) => {
    navigate(`/${Pages.Product}/${productId}`);
  };

  const shadowStyle = product.isForCarousel ? "info-col-row-admin-style" : "info-col-row"

  return (
    <>
      <MediaQuery minWidth={670}>
        <div className="main-block">
          <Row justify="space-between" className ={`${shadowStyle}`}>
            <Col className="info-col">
              <div className="info-block">
                <div className="advertising-info">
                  <Row justify={"center"}>
                    <a href={`/${Pages.Product}/${product.id}`} className="cardLabelLink">
                      <Row className="title-text cardLabelTitle">{product.name}</Row>
                    </a>
                  </Row>
                  <Row justify={"center"}>
                    <div className="description-text">
                      {" "}
                      {product.description}{" "}
                    </div>
                  </Row>
                </div>
                <Row justify="space-around" align={"middle"}>
                  <div className="cardPriceInline" style={{color: "black"}}>
                    <Row className="cardLabelOldPriceText">
                      $ {product.averagePrice.toFixed(2)}
                    </Row>
                    <Row className="cardLabelText">
                      $ {product.buy.toFixed(2)}
                    </Row>
                  </div>
                  <div className="cardPriceInline">
                    <Row align={"middle"} className="cardLabelText">
                      <ArrowDownOutlined />
                      {(
                        100 -
                        (product.buy * 100) / product.averagePrice
                      ).toFixed(0)}
                      % off
                    </Row>
                  </div>
                  {product.rating > 0 && (
                    <Rate disabled allowHalf value={product.rating / 10} />
                  )}
                </Row>
                <Row justify={"center"}>
                  <Button className="button-settings" onClick={() => navigate(`/${Pages.Product}/${product.id}`, { state: { product } })}>
                    See more
                  </Button>
                </Row>
              </div>
            </Col>
            <div className="image-settings-div">
              <Button className="card-clickable-image" onClick={() => navigateToProduct(product.id)}>
                {<img className="image-settings" alt="example" src={product.imageUrl} />}
              </Button>
            </div>
          </Row>
        </div>
      </MediaQuery>
      {/* <MediaQuery minWidth={670} maxWidth={1099}>
        <div className="main-block">
          <Row className="media-query-advertising-block">
            <div>
              <Image key={product.id} rootClassName="image-settings" src={product.imageUrl} />
            </div>
            <Col className="media-query-info-col">
              <div className="media-query-info-block">
                <div className="advertising-info">
                  <Row justify={"center"}>
                    <div className="title-text"> {product.name} </div>
                  </Row>
                  <Row justify={"center"}>
                    <div className="description-text">
                      {" "}
                      {product.description}{" "}
                    </div>
                  </Row>
                </div>
                <Row justify="space-around" align={"middle"}>
                  <div className="cardPriceInline">
                    <Row className="cardLabelOldPriceText">
                      $ {product.averagePrice.toFixed(2)}
                    </Row>
                    <Row className="cardLabelText">
                      $ {product.buy.toFixed(2)}
                    </Row>
                  </div>
                  <div className="cardPriceInline">
                    <Row align={"middle"} className="cardLabelText">
                      <ArrowDownOutlined />
                      {(
                        100 -
                        (product.buy * 100) / product.averagePrice
                      ).toFixed(0)}
                      % off
                    </Row>
                  </div>
                  {product.rating > 0 && (
                    <Rate disabled allowHalf value={product.rating / 10} />
                  )}
                </Row>
                <Row justify={"center"}>
                  <Button className="button-settings" onClick={() => navigate(`/${Pages.Product}/${product.id}`, { state: { product } })}>See more</Button>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </MediaQuery> */}
      <MediaQuery maxWidth={669}>
        <div className="main-block">
          <Row className="media-query2-advertising-block">
            <div className="image-settings-mobile-div">
              <Button className="card-clickable-image" onClick={() => navigateToProduct(product.id)}>
                {<img className="image-settings-mobile" alt="example" src={product.imageUrl} />}
              </Button>
            </div>
            <Col className="media-query2-info-col">
              <div className="media-query2-info-block">
                <div className="advertising-info">
                  <Row justify={"center"}>
                    <a href={`/${Pages.Product}/${product.id}`} className="cardLabelLink">
                      <Row className="title-text cardLabelTitle">{product.name}</Row>
                    </a>
                  </Row>
                  <Row justify={"center"}>
                    <div className="description-text">
                      {" "}
                      {product.description}{" "}
                    </div>
                  </Row>
                </div>
                <Row justify="space-around" align={"middle"}>
                  <div className="cardPriceInline">
                    <Row className="cardLabelOldPriceText">
                      $ {product.averagePrice.toFixed(2)}
                    </Row>
                    <Row className="cardLabelText">
                      $ {product.buy.toFixed(2)}
                    </Row>
                  </div>
                  <div className="cardPriceInline">
                    <Row align={"middle"} className="cardLabelText">
                      <ArrowDownOutlined />
                      {(
                        100 -
                        (product.buy * 100) / product.averagePrice
                      ).toFixed(0)}
                      % off
                    </Row>
                  </div>
                  {product.rating > 0 && (
                    <Rate disabled allowHalf value={product.rating / 10} />
                  )}
                </Row>
                <Row justify={"center"}>
                  <Button className="button-settings" onClick={() => navigate(`/${Pages.Product}/${product.id}`, { state: { product } })}>See more</Button>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </MediaQuery>
    </>
  );
};

export default AdvertisingProduct;
