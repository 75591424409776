import { useNavigate } from "react-router-dom";
import { PageSettings } from "../../models/PageSettings";
import notificationLogic from "../../components/notifiacation/notifiacation";
import { CarouselDto } from "../../models/CarouselDto";
import { ProductUpdateModel } from "../../models/ProductUpdateModel";
import Api from "./api";
import { AdminProductDto } from "../../models/AdminProductDto";
import { SiteProductsCriteriaDto } from "../../models/SiteProductsCriteriaDto";

export default class ApiService {
  navigate = useNavigate();

  async updateProduct(product: ProductUpdateModel) {
    try {
      await Api.put(`Product/Update`, product);
      return { success: true };
    } catch (error) {
      return { success: false, errorMessage: 'Failed to update product' };
    }
  };

  async deleteProduct(id: number) {
    try {
      const response = await Api.remove(`Product/${id}`);
      if (response.status === 200) {
        return { success: true };
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      return { success: false, errorMessage: 'Failed to delete product' };
    }
  }

  async blockProduct(id: number) {
    try {
      const response = await Api.remove(`Product/BlockProduct/${id}`);
      if (response.status === 200) {
        return { success: true };
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      return { success: false, errorMessage: 'Failed to block product' };
    }
  }

  uploadProducts = async (data: any) => {
    await Api.postFile("File/CheckProductsFromExcel", data)
      .then(() => {
        notificationLogic("success", `Files successfully added.`);
      })
      .catch(() => {
        notificationLogic("error", `Failed to add files.`);
      });
  };

  getProductById = async (id: number) => {
    try {
      const response = await (await Api.get(`Product/${id}`)).data;
      return response;
    } catch (error) {
      return { success: false, errorMessage: 'Failed to get product' };
    }
  };

  getRemovedIdAmzLinkById = async (id: number) => {
    try {
      console.log(id)
      const response = await (await Api.get(`Product/GetRemovedSiteProductIdAmzLink/${id}`)).data;
      return response;
    } catch (error) {
      return { success: false, errorMessage: 'Failed to get RemovedIdAmzLink' };
    }
  };

  getProductsByPage = async (
    NewPageSettings: PageSettings
  ) => {
    return (await Api.get(`Product/ProductsByCategoryByPage`,NewPageSettings)).data;
  };

  getBestSellerDeals = async (
    NewCarouselDto: CarouselDto
  ) => {
    return (await Api.get(`Carousel/BestSellerDeals`,NewCarouselDto)).data;
  };

  getLowestPriceDeals = async (
    NewCarouselDto: CarouselDto
  ) => {
    return (await Api.get(`Carousel/LowestPriceDeals`,NewCarouselDto)).data;
  };

  getCarouselProductByCategory = async (NewCarouselDto: CarouselDto) => {
    return (await Api.get(`Carousel/CarouselProductsByCategory`, NewCarouselDto)).data;
  };

  async setProductForCarouselStatus(id: number, status: boolean) {
    try {
      await Api.put(`Product/ChangeProductForCarouselStatus/${id}/${status}`);
      return { success: true };
    } catch (error) {
      return { success: false, errorMessage: 'Failed to update product' };
    }
  };

  setUpSubcategories = async (data: any) => {
    await Api.postJson("Product/SetUpSubcategories", data)
  };

  CreateProductFromAdmin = async (product: AdminProductDto) => {
    return (await Api.postJson("Product/CreateAdminProduct", product))
  };

  async updateProductsCriteria(criteria: SiteProductsCriteriaDto) {
    try {
      await Api.put(`Criteria/Update`, criteria);
      return { success: true };
    } catch (error) {
      return { success: false, errorMessage: 'Failed to update criteria' };
    }
  };

  getCurrentProductsCriteria = async () => {
    return (await Api.get(`Criteria/GetCriteria`)).data;
  };
}