import { Carousel, Spin } from "antd";
import { useEffect } from "react";
import MediaQuery from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Categories from "../../../../constants/categories";
import { CarouselDto } from "../../../../models/CarouselDto";
import ApiService from "../../../../services/Api/api-service";
import { useTable } from "../../../../stores/Store";
import "./advertising.css";
import AdvertisingProduct from "./advertisingProducts";

const Advertising = () => {
  const [state, actions] = useTable();
  let api = new ApiService();
  
  useEffect(() => {
    if(state.category !== undefined && state.category !== null && state.category !== ''){
      getCarouselProducts()  
    }
  }, [state.dealType, state.category, state.department, state.subdepartment]);

  const getCarouselProducts = async () => {
      const NewCarouselSettings: CarouselDto = {
        Category: state.category ?? Categories.All,
        Department: state.department,
        Subdepartment: state.subdepartment,
        Size: 10,
      };

    await api
      .getCarouselProductByCategory(NewCarouselSettings)
      .then((res) => {
        actions.setCarouselProducts(res)
      });
  };

  const settings = {
    initialSlide: 0,
    slidesToShow: 1,
    centerMode: state.carouselProducts.length > 1,
    arrows: true,
    dots: true,
    centerPadding: state.carouselProducts.length > 1 ? '170px' : '0px',
    infinite: true,
    variableWidth: state.carouselProducts.length > 1 ? true : false,
    autoplaySpeed: 3000,
    autoplay: true    
  };

  return (  
    <>    
      <Spin spinning={state.productsLoading} style={{marginTop: "-20px"}}>
      <MediaQuery minWidth={670}>
        <h1 className="main-page-title main-page-title-desktop">Most Popular Deals</h1>
        <Slider {...settings} className="center-slider">
          {state.carouselProducts.map(product => (
            <div key={product.id}>
              <AdvertisingProduct product={product}/>
            </div>
          ))}
        </Slider>
      </MediaQuery>
      <MediaQuery maxWidth={669}>
        <h1 className="main-page-title main-page-title-mobile">Most Popular Deals</h1>
        <Carousel autoplay effect="fade" style={{ marginTop: "0", marginBottom: "20px" }} arrows={true}>            
          {state.carouselProducts.map(product => (
            <AdvertisingProduct key={product.id} product={product}/>)
          )}                        
        </Carousel>   
      </MediaQuery>
      </Spin>
    </>
  );
};

export default Advertising;
