enum Categories {
  All = "All",
  Appliances = "Appliances",
  ArtsAndCrafts = "Arts and Crafts",
  BabyProducts = "Baby Products",
  BeautyAndPersonalCare = "Beauty and Personal Care",
  ClothingShoesAndJewelry = "Clothing, Shoes, and Jewelry",
  Electronics = "Electronics",
  GroceryAndGourmet = "Grocery & Gourmet",
  HealthAndHousehold = "Health & Household",
  HomeAndKitchen = "Home and Kitchen",
  OfficeProducts = "Office Products",
  PatioLawnAndGarde = "Patio, Lawn and Garden",
  PetSupplies = "Pet Supplies",
  SportsAndOutdoors = "Sports and Outdoors",
  ToolsAndHomeImprovement = "Tools and Home Improvement",
  ToysAndGames = "Toys and Games",
  VideoGames = "Video Games",
};

export default Categories;
