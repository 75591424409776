import {
  ArrowDownOutlined,
  FacebookOutlined,
  InstagramOutlined
} from "@ant-design/icons";
import { Button, Card, Rate, Row } from "antd";
import { useEffect } from "react";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import Pages from "../../../../constants/pages";
import SocialMediaLinks from "../../../../constants/socialMediaLinks";
import { ProductDto } from "../../../../models/ProductDto";
import "./products.css";
import TelegramLogo from "../../../../logos/telegLogo.svg"

const ProductCard = ({ product }: { product: ProductDto }) => {
  let navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigateToProduct = (productId: number) => {
    navigate(`/${Pages.Product}/${productId}`);
  };

  return (
    <>
      <MediaQuery minWidth={670}>   
        <Card className="productCard" hoverable>
          <div className="cardContainer">
            <div className="cardMainContainer">
              <div className="imageContainer">
                <Button className="card-clickable-image" onClick={() => navigateToProduct(product.id)}>
                  {<img className="productCardImage" alt="example" src={product.imageUrl} />}
                </Button>
              </div>
              <div className="cardInfo">
                <a href={`/${Pages.Product}/${product.id}`} className="cardLabelLink">
                  <Row className="cardLabelText cardLabelTitle">{product.name}</Row>
                </a>
                <Row className="cardDescriptionText">{product.description}</Row>
              </div>
            </div>
            <div className="cardNavigation">
              <div className="cardPrice">
                <div className="cardPriceColumns">
                  <div className="cardPriceInline">
                    <Row className="cardLabelOldPriceText">
                      $ {product.averagePrice.toFixed(2)}
                    </Row>
                    <Row className="cardLabelText">
                      $ {product.buy.toFixed(2)}
                    </Row>
                  </div>
                  <div className="cardPriceInline">
                    <Row align={"middle"} className="cardLabelText">
                      <ArrowDownOutlined />
                      {(
                        100 -
                        (product.buy * 100) / product.averagePrice
                      ).toFixed(0)}
                      % off
                    </Row>
                  </div>
                </div>
                {product.rating > 0 && (
                  <Rate disabled allowHalf value={product.rating / 10} />
                )}
              </div>
              <Button className="cardBuyButton" onClick={() => navigateToProduct(product.id)}>
                See more
              </Button>
              <div className="cardSocialNetworks">
                <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
                  <Button
                    className="productPage-social-media-button" icon={<InstagramOutlined className="productCardSocialNetworksIcon" />}
                  />
                </a>
                <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="productPage-telegram-logo-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
                <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
                  <Button
                    className="productPage-social-media-button" icon={<FacebookOutlined className="productCardSocialNetworksIcon" />}
                  />
                </a>
              </div>
            </div>
          </div>
        </Card>
      </MediaQuery>
      <MediaQuery minWidth={0} maxWidth={669}>
        <Card className="productCardTablet" hoverable>
          <div className="cardContainerMobile">
            <div className="imageContainerMobile">
              <Button className="card-clickable-image" onClick={() => navigateToProduct(product.id)}>
                {<img className="productCardImageMobile" alt="example" src={product.imageUrl} />}
              </Button>
            </div>
            <div className="cardInfo">
              <a href={`/${Pages.Product}/${product.id}`} className="cardLabelLink">
                <Row className="cardLabelText cardLabelTitle">{product.name}</Row>
              </a>
              <Row className="cardDescriptionText">{product.description}</Row>
            </div>
            <div className="cardPrice">
              <div className="cardPriceColumns">
                <div className="cardPriceInline">
                  <Row className="cardLabelOldPriceText">
                    $ {product.averagePrice.toFixed(2)}
                  </Row>
                  <Row className="cardLabelText">
                    $ {product.buy.toFixed(2)}
                  </Row>
                </div>
                <div className="cardPriceInline">
                  <Row align={"middle"} className="cardLabelText">
                    <ArrowDownOutlined />
                    {(100 - (product.buy * 100) / product.averagePrice).toFixed(
                      0
                    )}
                    % off
                  </Row>
                </div>
              </div>
              {product.rating > 0 && (
                <Rate disabled allowHalf value={product.rating / 10} />
              )}
            </div>
            <Button className="cardBuyButton" onClick={() => navigateToProduct(product.id)}>
              See more
            </Button>
            <div className="cardSocialNetworks">
            <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
              <Button
                className="productPage-social-media-button" icon={<InstagramOutlined className="productCardSocialNetworksIcon" />}
              />
            </a>
            <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
              <Button className="productPage-telegram-logo-button" >
                {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
              </Button> 
            </a>
            <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
              <Button
                className="productPage-social-media-button" icon={<FacebookOutlined className="productCardSocialNetworksIcon" />}
              />
            </a>
            </div>
          </div>
        </Card>
      </MediaQuery> 
    </>
  );
};

export default ProductCard;
