import { Button, Form, Input } from "antd";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import AuthorizeService from "../../../services/Api/autorise-service";
import { useTable } from "../../../stores/Store";
import AuthStore from "../../../stores/auth-store";

const Login = () => {
  const [state, actions] = useTable();
  let authService = new AuthorizeService();
  let user: any;
  let navigate = useNavigate();

  const onFinish = async (values: any) => {
    actions.setLoginLoading(true);
    await authService.login(values);
    const token = AuthStore.getToken() as string;
    if (token !== "") user = jwt(token);
    navigate(`/`)
    actions.setLoginLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
      }}
    >
      <Form
        name="basic"
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
