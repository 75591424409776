import { Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useParams } from "react-router-dom";
import FooterFilling from "../../../footter/footerFilling";
import HeaderFilling from "../../../header/headerFilling";
import MenuDrawer from "../../../menu/menuDrawer";
import ProductCardPage from "./productCardPage";
import "./productCardPage.css";

const ProductPage = () => {
  const { productId } = useParams<{ productId: string }>();

  const parsedProductId = productId ? parseInt(productId, 10) : 0;

  return (
    <>
      <Layout>
        <Header className="header">
          <HeaderFilling />
        </Header>
        <Content className="contentHeaderPage">
          <ProductCardPage productId={parsedProductId} />
        </Content>
        <Footer className="footerHeaderPage">
          <FooterFilling/> 
        </Footer>
        <MenuDrawer/>
      </Layout>
    </>
  );
};

export default ProductPage;
