import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Categories from '../../constants/categories';
import DealTypes from '../../constants/dealTypes';
import Pages from '../../constants/pages';
import { useTable } from '../../stores/Store';

const DropdownMenu = () => {
  let navigate = useNavigate();
  
  const [state, actions] = useTable();
  const [categories, setCategories] = useState<(keyof typeof Categories)[]>(Object.values(Categories) as (keyof typeof Categories)[]);
  const [columns, setColumns] = useState<React.ReactNode[][]>([]);

  useEffect(() => {
    setColumns(chunkArray(categories, 4));
  }, [categories]);

  const chunkArray = (arr: (keyof typeof Categories)[], size: number) => {
    const chunkedArray: (keyof typeof Categories)[][] = [];
    let index = 0;
    while (index < arr.length) {
      chunkedArray.push(arr.slice(index, index + size));
      index += size;
    }
    return chunkedArray;
  };

  const choosingCategories = (menuOption: any) => {
    actions.setPage(1);
    const category = menuOption.key.split(/[ ,]+/)[0];
    actions.setCategory(category);
    actions.setSelectedMenuItem(menuOption.key);
    actions.setSearchedData("");
    actions.setDealType(DealTypes.NewestDeals);
    navigate(`/${Pages.Home}/${category}/1/${state.pageSize}`);
  };

  const onClick = (menuOption: any) => {
    choosingCategories(menuOption);
  };

  const menu = (
    <Menu onClick={onClick} className="dropdown-menu-columns" selectedKeys={[state.selectedMenuItem]}>
      {columns.map((column, index) => (
        <Menu.ItemGroup key={index}>
          {column.map((category) => (
            <Menu.Item key={category?.toString()} className="dropdown-menu-item">{category}</Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottom" className="dropdown-menu" overlayStyle={{ width: '100%', overflowX: 'hidden' }}>
      <Button className="header-button deals-button" type="link">
        Deals <DownOutlined className="down-icon"/>
      </Button>
    </Dropdown>
  );
};

export default DropdownMenu;