import { Input, Modal, Select } from "antd";

interface ModalFormProps {
    visible: boolean;
    onCancel: () => void;
    handleChooseOption: (option: any) => void;
    handleSearch: (value: string) => void;
    selectOptions: { value: string, label: string }[];
    chosenSearchOption: { value: string, label: string };
    searchedData: string;
    defaultValue: { value: string, label: string}
}

const SearchModal = ({ 
    visible, 
    onCancel, 
    handleChooseOption, 
    handleSearch,  
    selectOptions,
    chosenSearchOption
 }: ModalFormProps) => {
    
  return (
      <Modal
        title="Search"
        open={visible}
        onCancel={onCancel}
        footer={null}
        style={{width: "100%", margin: "0 auto"}}
      >
        <Input.Group style={{width:"100%"}} >
            <Select
              defaultValue={selectOptions[0]}
              onSelect={handleChooseOption}        
              options={selectOptions}
              value={chosenSearchOption}
              style={{width:"35%"}}
            />                      
            <Input.Search 
              placeholder="Search for items..." 
              onSearch={handleSearch} 
              style={{width:"65%"}}
            /> 
          </Input.Group>  
      </Modal>
  );
};

export default SearchModal;