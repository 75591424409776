import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Row } from "antd";
import { useState } from "react";
import SubscriberService from "../../services/Api/subscriber-service";
import notificationLogic from "../notifiacation/notifiacation";
import "./subscribeModal.css";
import TelegramLogo from "../../logos/telegLogo.svg"
import SocialMediaLinks from "../../constants/socialMediaLinks";

interface ModalFormProps {
  visible: boolean;
  onClose: () => void;
}

const SubscribeModal = ({ visible, onClose }: ModalFormProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [form] = Form.useForm();
  let api = new SubscriberService();

  const handleSubmit = () => {
    form.validateFields().then(async ({ email }) => {
      await api.saveSubscribersEmail(email);
      notificationLogic("success", "You have subscribed to our newsletter");
      form.resetFields();
      onClose();
    });
    setFormSubmitted(true);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const validateMessages = {
    required: "Field is required",
    types: {
      email: "Email is not valid"
    },
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      footer={null}
      className="subscribe-modal"
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="modal-content">
        <div className="modal-body">
          <h2 className="modal-title">
            Sign up and get our exclusive offers delivered directly to your
            inbox
          </h2>
          <Form 
            validateMessages={validateMessages}
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item 
              name="email" 
              rules={[{ type: "email", required: true }]}
              validateTrigger={"onFinish"}
              style={{marginBottom:"10px"}}
            >
              <Input 
                allowClear
                placeholder="Your email address" 
                className="email-input" 
              />
            </Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              className="submit-button"
            >
              SUBSCRIBE NOW
            </Button>
            <Row className="followUs subscribe-modal-follow-us" justify={'center'}>Follow us</Row>
            <div className="cardSocialNetworks">
                <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
                  <Button
                    className="productPage-social-media-button" icon={<InstagramOutlined className="productCardSocialNetworksIcon" />}
                  />
                </a>
                <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="productPage-telegram-logo-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
                <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
                  <Button
                    className="productPage-social-media-button" icon={<FacebookOutlined className="productCardSocialNetworksIcon" />}
                  />
                </a>
              </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
