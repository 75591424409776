import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Row } from "antd";
import MediaQuery from "react-responsive";
import Categories from "../../constants/categories";
import MenuItems from "../../constants/menuItems";
import Pages from "../../constants/pages";
import SocialMediaLinks from "../../constants/socialMediaLinks";
import logo_desktop from "../../logo_black.svg";
import RefreshButton from "../header/refreshButton";
import "./footer.css";
import FooterButton from "./footerButton";
import TelegramLogo from "../../logos/telegLogo.svg"

const FooterFilling = () => {
  let now = new Date();

  return (
    <>
      <MediaQuery minWidth={1100}>
        <Row justify="start">
          <RefreshButton path={`/${Pages.Home}/${Categories.All}/1/30`} className="footer-button logo-footer-button">{<img src={logo_desktop} alt="" className="footer-logo-image" />}</RefreshButton>
          <FooterButton path={`/${Pages.Home}/${Categories.All}/1/30`} text={MenuItems.Home} className="footer-button"/>
          <FooterButton path={`/${Pages.About}`} text={MenuItems.About} className="footer-button" />
        </Row>
        <Divider />
        <Row style={{ marginBottom: "15px", width: "40%" }} id="Disclosure" className="Disclosure">
            Disclosure: We are a participant in the Amazon Services LLC
            Associates Program, an affiliate advertising program designed to
            provide a means for sites to earn advertising fees by advertising
            and linking to amazon.com. As an Amazon Associate we earn from
            qualifying purchases.
          </Row>
        <Row>          
          <Col span={12} style={{ display: "flex", justifyContent: "start" }}>
            © {now.getFullYear()} - All rights reserved
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
          <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="footer-social-media-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
            <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
              <Button
                className="footer-social-media-button"
                icon={<FacebookOutlined className="socialNetworksIcon-footer" />}
              />
            </a>
            <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
              <Button
                className="footer-social-media-button"
                icon={<InstagramOutlined className="socialNetworksIcon-footer" />}
              />
            </a>
          </Col>
        </Row>
      </MediaQuery>
      <MediaQuery minWidth={500} maxWidth={1099}>
        <Row justify="start">
        <RefreshButton path={`/${Pages.Home}/${Categories.All}/1/30`} className="footer-button logo-footer-button">{<img src={logo_desktop} alt="" className="footer-logo-image" />}</RefreshButton>
          <FooterButton
            path={`/${Pages.Home}/${Categories.All}/1/30`}
            text="Home"
            className="footer-button"
          />
          <FooterButton path={`/${Pages.About}`} text={MenuItems.About} className="footer-button" />
        </Row>
        <Divider />
        <Row id="Disclosure" className="Disclosure" style={{width: "70%"}}>
            Disclosure: We are a participant in the Amazon Services LLC
            Associates Program, an affiliate advertising program designed to
            provide a means for sites to earn advertising fees by advertising
            and linking to amazon.com. As an Amazon Associate we earn from
            qualifying purchases.
        </Row>
        <Row>          
          <Col span={12} style={{ display: "flex", justifyContent: "start" }}>
            © {now.getFullYear()} - All rights reserved
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
            <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="footer-social-media-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
            <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
              <Button
                className="footer-social-media-button"
                icon={<FacebookOutlined className="socialNetworksIcon-footer" />}
              />
            </a>
            <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
              <Button
                className="footer-social-media-button"
                icon={<InstagramOutlined className="socialNetworksIcon-footer" />}
              />
            </a>
          </Col>
        </Row>
      </MediaQuery>
      <MediaQuery minWidth={200} maxWidth={499}>
        <Row justify={"center"}>
        <RefreshButton path={`/${Pages.Home}/${Categories.All}/1/30`} className="footer-mobile-button logo-footer-button">{<img src={logo_desktop} alt="" className="footer-mobile-logo-image" />}</RefreshButton>
          <FooterButton path={`/${Pages.Home}/${Categories.All}/1/30`} text={MenuItems.Home} className="footer-mobile-button"/>
          <FooterButton path={`/${Pages.About}`} text={MenuItems.About} className="footer-mobile-button"/>
        </Row>
        <Divider />
        <Row id="Disclosure" className="Disclosure" >
          Disclosure: We are a participant in the Amazon Services LLC Associates
          Program, an affiliate advertising program designed to provide a means
          for sites to earn advertising fees by advertising and linking to
          amazon.com. As an Amazon Associate we earn from qualifying purchases.
        </Row>
        <Row justify={"center"} style={{ margin: "5px 0 5px 0" }}>
          © {now.getFullYear()} - All rights reserved
        </Row>
        <Row justify={"center"} style={{ margin: "5px 0 5px 0", justifyContent: "center" }}>
        <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="footer-social-media-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
          <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
            <Button
              className="footer-social-media-button"
              icon={<FacebookOutlined className="socialNetworksIcon-footer" />}
            />
          </a>
          <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
            <Button
              className="footer-social-media-button"
              icon={<InstagramOutlined className="socialNetworksIcon-footer" />}
            />
          </a>
        </Row>
      </MediaQuery>
    </>
  );
};

export default FooterFilling;
