import { useNavigate } from "react-router-dom";
import Api from "./api";

export default class BlockedProductsService {
  navigate = useNavigate();

    async unblockProduct(id: number) {
        try {
          const response = await Api.remove(`Product/UnblockProduct/${id}`);
          if (response.status === 200) {
            return { success: true };
          } else {
            throw new Error('Invalid response');
          }
        } catch (error) {
          return { success: false, errorMessage: 'Failed to unblock product' };
        }
      }
    
    getBlockedProducts = async () => {
        try {
          const response = await (await Api.get(`Product/GetBlockedProducts`)).data;
          return response;
        } catch (error) {
          return { success: false, errorMessage: 'Failed to get blocked products' };
        }
      };
}