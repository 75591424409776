enum Pages {
    Home = "home",
    About = "about",
    Product = "product",
    UploadFiles = "uploadFiles",
    BlockedProducts = "blockedProducts",
    ProductDesigner = "productDesigner",
    ProductsCriteria = "productsCriteria",
  };
  
  export default Pages;