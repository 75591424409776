import notificationLogic from "../../components/notifiacation/notifiacation";
import AuthStore from "../../stores/auth-store";
import Api from "./api";

export default class AuthorizeService {
    static isSignedIn(): boolean {
        return !!AuthStore.getToken();
    }

    login = async (data: any) => {
        try {
          const response = await Api.postJson("Authentication/login", data);
          console.log(response, 'response')
          if (response.data.token !== null) {
            AuthStore.setToken(response.data.token);
            await notificationLogic('success', 'You logged in');
          }
          
          return response;
        } catch (error: any) {
            console.log(error, 'error')
          if (error.response == null || error.response.status === 401) {
            AuthStore.removeToken();
            await notificationLogic('error', 'Wrong user or creds');
          }
          throw error;
        }
    };

    logout = async () => {
        AuthStore.removeToken();
        notificationLogic('success', 'You logged out');
    };
}