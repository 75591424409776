enum MenuItems {
    Home = "Home",
    About = "About",
    Categories = "Deals by category",
    UploadFiles = "Upload Files",
    BlockedProducts = "Blocked Products",
    ProductDesigner = "Product Designer",
    ProductsCriteria = "Products Criteria",
    LogOut = "LogOut"
  };
  
  export default MenuItems;