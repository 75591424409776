import Api from "./api";

export default class SubscriberService {
    saveSubscribersEmail = async (email: string) => {
        const response = await Api.postJson(`Subscriber/SaveEmail/${email}`, email)
        .catch((error) => {
            throw new Error(error);
        });
        return response;
    };
}