import { UpOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import "./scrollToTopButton.css";

const ScrollToTopButton: React.FC = () => {
    const [showButton, setShowButton] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        setShowButton(scrollPosition > 0);
      };
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    return (
      <>
        <MediaQuery minWidth={650}>
          <button
            className={`scroll-to-top-button-base scroll-to-top-button-pc ${showButton ? "visible" : ""}`}
            onClick={scrollToTop}
          >
            <UpOutlined className="arrow-icon" />
          </button>
        </MediaQuery>
        <MediaQuery minWidth={0} maxWidth={649}>
          <button
            className={`scroll-to-top-button-base scroll-to-top-button-mobile ${showButton ? "visible" : ""}`}
            onClick={scrollToTop}
          >
            <UpOutlined className="arrow-icon" />
          </button>
        </MediaQuery>
      </>
    );
  };
  
  export default ScrollToTopButton;
