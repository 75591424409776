enum Department {  
    All = "All",
    Women = "Women",
    Men = "Men",
    Girls = "Girls",
    Boys  = "Boys",
    Baby = "Baby",
    EverythingElse = "Everything else",
  };
  
  export default Department;