import { Button, Col, Form, InputNumber, Row } from "antd";
import { useEffect, useState } from "react";
import { SiteProductsCriteriaDto } from "../../../models/SiteProductsCriteriaDto";
import ApiService from "../../../services/Api/api-service";
import notificationLogic from "../../notifiacation/notifiacation";
import './productsCriteria.css';

const ProductsCriteriaPageFilling = () => {
    let api = new ApiService();
    const [currentCriteria, setCurrentCriteria] = useState<SiteProductsCriteriaDto>();

    const [form] = Form.useForm();

    useEffect(() => {
      if(currentCriteria === undefined){
        getCurrentCriteria(); 
      }
    }, []);        

    const getCurrentCriteria = async () => {        
      await api
      .getCurrentProductsCriteria()
      .then((res) => {
        setCurrentCriteria(res);     
        form.setFieldsValue(res);   
      });       
    }

    async function changeCriteria(criteria: any) {        
        let newCriteria: SiteProductsCriteriaDto = {
            id: 1,
            maxRank: criteria.maxRank,
            minMarkdown: criteria.minMarkdown,
            minRating: criteria.minRating
        };
        
        var response = await api.updateProductsCriteria(newCriteria);  
        if (response.success) {
          notificationLogic('success', `Criteria was successfully updated!`);        
        } else {
          notificationLogic('Error', response.errorMessage);
        }  
      }

    return (
      <>
        <Row justify="center" style={{margin: '120px 0px'}}>
          <Col><div className="about-title text-color-align-line-height">Products Criteria</div></Col>
        </Row>
        <Row gutter={12} align="middle" justify="space-around">
            <Form
              name="basic"
              form={form}
              labelAlign="left"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              style={{
                width: "1000px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              onFinish={changeCriteria}
            >              
              <Row justify={'center'}>
                {/* <p  className="cardLabelText" style={{marginRight:"25px"}}>Criterias:</p> */}
                <Form.Item
                  className="cardLabelText"
                  label="MinMarkdown"
                  name="minMarkdown"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber min={0} max={100} defaultValue={currentCriteria?.minMarkdown} style={{width:"150px"}}/>
                </Form.Item> 
                <Form.Item
                  className="cardLabelText"
                  label="MinRating"
                  name="minRating"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber max={50} min={0} defaultValue={currentCriteria?.minRating} style={{width:"150px"}}/>
                </Form.Item> 
                <Form.Item
                  className="cardLabelText"
                  label="MaxRank"
                  name="maxRank"
                  labelCol={{ span: 24 }}                
                >
                  <InputNumber defaultValue={currentCriteria?.maxRank} style={{width:"150px"}}/>
                </Form.Item> 
              </Row>           
              <Row style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width:"200px", height:"40px", marginTop: "20px"}}>
                      Update
                    </Button>
                  </Form.Item>
                </Col>                
              </Row>              
            </Form>     
          </Row>
      </>
      )};
  
export default ProductsCriteriaPageFilling;