import { Button, Col, Modal, Row, Table } from "antd";
import { useEffect } from "react";
import MediaQuery from "react-responsive";
import { BlockedProductsDto } from "../../../models/BlockedProductsDto";
import BlockedProductsService from "../../../services/Api/blockedProducts-service";
import { useTable } from "../../../stores/Store";

const UploadFilesPage = () => {
    let api = new BlockedProductsService();
    const [state, actions] = useTable();

    useEffect(() => {
      getBlockedProducts();     
    }, []);    

    const columns = [
        {
          key: 'id',
          title: 'Id',
          dataIndex: 'id',
        },
        {
          key: 'parentAsin',
          title: 'Parent Asin',
          dataIndex: 'parentAsin',
        },
        {
          key: 'asin',
          title: 'Asin',
          dataIndex: 'asin',
        },
        {
          key: 'title',
          title: 'Title',
          dataIndex: 'title',
        },
        {
          key: 'actions',
          title: 'Actions',
          dataIndex: 'actions',
          render: (text: any, record: BlockedProductsDto) => (
            <Button type="primary" onClick={() => handleUnblock(record.id)}>Unblock</Button>
          ),
        },
    ];

    const getBlockedProducts = async () => {        
      await api
      .getBlockedProducts()
      .then((res) => {
        actions.setBlockedProducts(res);        
        console.log(res)
      });       
    }

    function handleUnblock(id: number) {
      Modal.confirm({
        title: 'Are you sure, you want to unblock this product?',
        onOk: async () => {
          await api.unblockProduct(id);
          await api
            .getBlockedProducts()
            .then((res) => {
              actions.setBlockedProducts(res);
            }
          );
        }
      })
    }

    return (
      <>
        <MediaQuery minWidth={810}>
        <Row justify="center" style={{margin: '120px 0px'}}>
          <Col><div className="about-title text-color-align-line-height">Blocked Products</div></Col>
        </Row>
        <Table dataSource={state.blockedProducts} columns={columns} />
        </MediaQuery>
      </>
    );
  };
  
  export default UploadFilesPage;