import { Action, createHook, createStore } from "react-sweet-state";
import DealTypes from "../constants/dealTypes";
import { BlockedProductsDto } from "../models/BlockedProductsDto";
import { ProductDto } from "../models/ProductDto";

type State = {
  category: any;
  dealType: any;
  department: any;
  subdepartment: any;
  selectedMenuItem: any
  page: number;
  pageSize: number;
  autorized: boolean;
  searchedData: string;
  chosenSearchOption: {value: string, label: string};
  products: ProductDto[];
  totalProducts: number;
  productsLoading: boolean;
  loginLoading: boolean;
  advertisingLoading: boolean;
  inputGroupVisibility: boolean;
  visibleSearchModal: boolean;
  visibleSideMenu: boolean;
  visibleSubscribeModal: boolean;
  showMenu: boolean;
  signedIn: boolean;
  carouselProducts: ProductDto[];
  bestSellerProducts: ProductDto[];
  lowestPriceProducts: ProductDto[];
  blockedProducts: BlockedProductsDto[];
};

const initialState: State = {
  category: "",
  dealType: DealTypes.NewestDeals,
  department:"",
  subdepartment: "",
  selectedMenuItem: "",
  page: 1,
  pageSize: 30,
  autorized: false,
  searchedData: "",
  chosenSearchOption: {value: "All", label: 'All'},
  products: [],
  totalProducts: 0,
  productsLoading: true,
  loginLoading: true,
  advertisingLoading: true,
  inputGroupVisibility: true,
  visibleSearchModal: false,
  visibleSideMenu: false,
  visibleSubscribeModal: false,
  showMenu: false,
  signedIn: false,
  carouselProducts: [],
  bestSellerProducts: [],
  lowestPriceProducts: [],
  blockedProducts: []
};

const actions = {
  setPage:
    (pageNum: number): Action<State> =>
    ({ setState }) => {
      setState({
        page: pageNum,
      });
    },
  setPageSize:
    (size: number): Action<State> =>
    ({ setState }) => {
      setState({
        pageSize: size,
      });
    },

    setAutorise:
    (autorise: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        autorized: autorise,
      });
    },

    setSearchedData:
    (searchData: string): Action<State> =>
    ({ setState }) => {
      setState({
        searchedData: searchData
      });
    },

    setCategory:
    (category: string): Action<State> =>
    ({ setState }) => {
      setState({
        category: category
      });
    },

    setDealType:
    (dealType: string): Action<State> =>
    ({ setState }) => {
      setState({
        dealType: dealType
      });
    },

    setDepartment:
    (department: string): Action<State> =>
    ({ setState }) => {
      setState({
        department: department
      });
    },

    setSubdepartment:
    (subdepartment: string): Action<State> =>
    ({ setState }) => {
      setState({
        subdepartment: subdepartment
      });
    },

    setSelectedMenuItem:
    (selectedMenuItem: string): Action<State> =>
    ({ setState }) => {
      setState({
        selectedMenuItem: selectedMenuItem
      });
    },

    setChosenSearchOption:
    (data: {value: string, label: string}): Action<State> =>
    ({ setState }) => {
      setState({
        chosenSearchOption: data
      });
    },

    setInputGroupVisibility:
    (value: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        inputGroupVisibility: value
      });
    },

    setTotalProducts:
    (newTotal: number): Action<State> =>
    ({ setState }) => {
      setState({
        totalProducts: newTotal
      });
    },

    setProductsLoading:
    (loading: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        productsLoading: loading
      });
    },

    setLoginLoading:
    (loading: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        loginLoading: loading
      });
    },

    setAdvertisingLoading:
    (loading: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        advertisingLoading: loading
      });
    },

    setProducts:
    (newProducts: ProductDto[]): Action<State> =>
    ({ setState }) => {
      setState({
        products: newProducts
      });
    },

    setBlockedProducts:
    (newProducts: BlockedProductsDto[]): Action<State> =>
    ({ setState }) => {
      setState({
        blockedProducts: newProducts
      });
    },

    setVisibleSearchModal:
    (visible: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        visibleSearchModal: visible
      });
    },

    setSideMenuVisible:
    (visible: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        visibleSideMenu: visible
      });
    },

    setVisibleSubscribeModal:
    (visible: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        visibleSubscribeModal: visible
      });
    },
    
    setShowMenu:
    (isShow: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        showMenu: isShow,
      });
    },

    setSignedIn:
    (isSigned: boolean): Action<State> =>
    ({ setState }) => {
      setState({
        signedIn: isSigned,
      });
    },

    setCarouselProducts:
    (newProducts: ProductDto[]): Action<State> =>
    ({ setState }) => {
      setState({
        carouselProducts: newProducts
      });
    },

    setBestSellerProducts:
    (newProducts: ProductDto[]): Action<State> =>
    ({ setState }) => {
      setState({
        bestSellerProducts: newProducts
      });
    },

    setLowestPriceProducts:
    (newProducts: ProductDto[]): Action<State> =>
    ({ setState }) => {
      setState({
        lowestPriceProducts: newProducts
      });
    },
};

const Store = createStore({ initialState, actions });
export const useTable = createHook(Store);
