import { LogoutOutlined, MailOutlined, MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import Categories from "../../constants/categories";
import HeaderSearchBarSelectOptions from "../../constants/headerSearchBarSelectOptions";
import MenuItems from "../../constants/menuItems";
import Pages from "../../constants/pages";
import logo_desktop from "../../logo_black.svg";
import logo_mobile from "../../logo_white.svg";
import { PageSettings } from "../../models/PageSettings";
import ApiService from "../../services/Api/api-service";
import AuthorizeService from "../../services/Api/autorise-service";
import { useTable } from "../../stores/Store";
import DropdownMenu from "./dropDownMenu";
import HeaderButton from "./headerButton";
import RefreshButton from "./refreshButton";
import SearchModal from "./searchModal";
import SubscribeModal from "./subscribeModal";

const HeaderFilling = () => {
  let navigate = useNavigate();
  let api = new ApiService();

  const [state, actions] = useTable();
  const [searchButtonPressed, setSearchButtonPressed] = useState(false);
  const authorizeService = new AuthorizeService();

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisited');

    if (!hasVisitedBefore) {
      actions.setVisibleSubscribeModal(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const LogOutClick = () => {
    actions.setLoginLoading(true);
    authorizeService.logout();
    actions.setSignedIn(AuthorizeService.isSignedIn());
    actions.setLoginLoading(false);
  };

  useEffect(() => {
    actions.setSignedIn(AuthorizeService.isSignedIn());
  }, [state.signedIn]);

  useEffect(() => {
    if (searchButtonPressed) {
      getProducts();
      setSearchButtonPressed(false);
    }
  }, [searchButtonPressed, state.searchedData, state.chosenSearchOption]);

  const getProducts = async () => {    
    actions.setProductsLoading(true);

    const NewPageSettings: PageSettings = {
      Category: state.category ?? Categories.All,
      PageNumber: (state.page ?? 1),
      PageSize: (state.pageSize ?? 30),
      SearchedData: state.searchedData,
      ChosenSearchOption: state.chosenSearchOption.value
    };
    await api
      .getProductsByPage(NewPageSettings)
      .then((res) => {
        actions.setProducts(res.item1);
        actions.setTotalProducts(res.item2);
      });
      actions.setProductsLoading(false);
  };

  const handleSearch = (searchedData: string) => {
    if(state.page !== 1 || !window.location.pathname.includes('home')){
      navigate(`/${Pages.Home}/${state.category}/1/30`)
    }
    actions.setPage(1);
    actions.setSearchedData(searchedData);
    actions.setVisibleSearchModal(false);
    setSearchButtonPressed(true);
  };

  const handleChooseOption = (option: any) => {   
    if(option === HeaderSearchBarSelectOptions[0].value)
    {
      actions.setSearchedData("");
    }
    actions.setChosenSearchOption({value:option, label:option});
  };

  return (
    <>
    <MediaQuery minWidth={1250}>
        <Button type="text" icon={<MenuOutlined/>} className="header-showMenu-button" onClick={() => actions.setShowMenu(true)}/>
        <RefreshButton path={`/${Pages.Home}/${Categories.All}/1/30`} className="header-button-logo">{<img src={logo_desktop} alt="" className="logo-image"/>}</RefreshButton>
        <Input.Group style={{width:"50%", margin: "0 15px 0 15px"}} >
          <Select 
            defaultValue={HeaderSearchBarSelectOptions[0]}
            onSelect={handleChooseOption}        
            options={HeaderSearchBarSelectOptions}
            value={state.chosenSearchOption}
            style={{width:"20%"}}
          />               
          <Input.Search 
            placeholder="Search" 
            onSearch={handleSearch}  
            style={{width:"80%"}}
          /> 
        </Input.Group>              
        <HeaderButton path={`/${Pages.Home}/${Categories.All}/1/30`} text={MenuItems.Home} className="header-button"/>        
        <DropdownMenu/>
        <HeaderButton path={`/${Pages.About}`} text={MenuItems.About} className="header-button"/>   
        <Tooltip placement="bottom" title="Subscribe">   
          <Button className="email-button-tablet" icon={<MailOutlined />} onClick={() => actions.setVisibleSubscribeModal(true)}/>
        </Tooltip> 
          <SubscribeModal
            visible={state.visibleSubscribeModal}
            onClose={() => actions.setVisibleSubscribeModal(false)}
          /> 
        {state.signedIn && (
          <Tooltip placement="bottom" title={MenuItems.LogOut}>
            <Button danger onClick={LogOutClick} icon={<LogoutOutlined />} className="logout-button"/>
          </Tooltip>
        )}             
    </MediaQuery>
    <MediaQuery minWidth={450} maxWidth={1249}>
      <Button type="text" icon={<MenuOutlined/>} className="header-showMenu-button" onClick={() => actions.setShowMenu(true)}/>
      <RefreshButton path={`/${Pages.Home}/${Categories.All}/1/30`} className="header-button-logo">{<img src={logo_desktop} alt="" className="logo-image-mobile"/>}</RefreshButton>
      <Button className="email-button-tablet" icon={<MailOutlined />} onClick={() => actions.setVisibleSubscribeModal(true)}/>
        <SubscribeModal
          visible={state.visibleSubscribeModal}
          onClose={() => actions.setVisibleSubscribeModal(false)}
        />
      <Button className="search-button-tablet" icon={<SearchOutlined />} onClick={() => actions.setVisibleSearchModal(true)}/>
      <SearchModal 
        visible={state.visibleSearchModal} 
        onCancel={() => actions.setVisibleSearchModal(false)}
        handleChooseOption={handleChooseOption}
        handleSearch={handleSearch}
        selectOptions={HeaderSearchBarSelectOptions}
        chosenSearchOption={state.chosenSearchOption}
        searchedData={state.searchedData}
        defaultValue={HeaderSearchBarSelectOptions[0]}
      />            
      {state.signedIn && (
        <Button danger onClick={LogOutClick} icon={<LogoutOutlined />} className="logout-button"/>
      )}
    </MediaQuery>
    <MediaQuery maxWidth={449}>
      <Row justify="center" className="header-mobile">
        <Col span={4}>
          <Button className="burger-button-mobile" icon={<MenuOutlined />} onClick={() => actions.setShowMenu(true)}/>
        </Col>
        <Col span={16} style={{flex: 'none', height: 'inherit'}}>
          <RefreshButton path={`/${Pages.Home}/${Categories.All}/1/30`} className="header-button-logo header-button-logo-mobile">{<img src={logo_mobile} alt="" className="logo-image-mobile"/>}</RefreshButton>
        </Col>
        <Col span={4}>  
          <Button className="email-button-mobile" icon={<MailOutlined />} onClick={() => actions.setVisibleSubscribeModal(true)}/>
          <SubscribeModal
            visible={state.visibleSubscribeModal}
            onClose={() => actions.setVisibleSubscribeModal(false)}
          />
          <Button className="search-button-mobile" icon={<SearchOutlined />} onClick={() => actions.setVisibleSearchModal(true)}/>
          <SearchModal 
            visible={state.visibleSearchModal} 
            onCancel={() => actions.setVisibleSearchModal(false)}
            handleChooseOption={handleChooseOption}
            handleSearch={handleSearch}
            selectOptions={HeaderSearchBarSelectOptions}
            chosenSearchOption={state.chosenSearchOption}
            searchedData={state.searchedData}
            defaultValue={HeaderSearchBarSelectOptions[0]}
          />     
        </Col>
      </Row>
    </MediaQuery>
    </>
  );
};

export default HeaderFilling;
