import { Col, Pagination, Row, Select, Spin } from "antd";
import { useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import Categories from "../../../../constants/categories";
import DealTypes from "../../../../constants/dealTypes";
import Pages from "../../../../constants/pages";
import { PageSettings } from "../../../../models/PageSettings";
import { ProductDto } from "../../../../models/ProductDto";
import ApiService from "../../../../services/Api/api-service";
import { useTable } from "../../../../stores/Store";
import ProductCard from "./productCard";

const Products = () => {
  const [state, actions] = useTable();
  let api = new ApiService();

  let navigate = useNavigate();
  const { pathname: currentPath } = useLocation();

  const regexPathPatterns =  [/\/home\/([^/]+)\/\d+\/\d+/,  /\/home\/([^/]+)\/([^/]+)\/\d+\/\d+/, /\/home\/([^/]+)\/([^/]+)\/([^/]+)\/\d+\/\d+/]

  useEffect(() => {
    if (state.category === "") {
      if (!currentPath.includes(`/${Pages.Home}/${Categories.All}`)) {   
        let defaultSetting = true   
        regexPathPatterns.map(pattern =>
          {
          const patternMatch = currentPath.match(pattern);
          if(patternMatch !== null){            
            const category = patternMatch[1] === null ?  Categories.All : patternMatch[1];
            const department = patternMatch[2] === undefined ? "" :  patternMatch[2];
            const subdepartment = patternMatch[3] === undefined ? "" : patternMatch[3];

            actions.setCategory(category);
            actions.setDepartment(department);
            actions.setSubdepartment(subdepartment);
            actions.setSelectedMenuItem(category);
            defaultSetting = false
            navigate(currentPath);
          }
        })  
        
        if(defaultSetting){
          actions.setCategory(Categories.All);
          actions.setSelectedMenuItem(Categories.All);
          navigate(currentPath);
        }
        
      } else {
        actions.setCategory(Categories.All);
        actions.setSelectedMenuItem(Categories.All);
        navigate(`/${Pages.Home}/${Categories.All}/1/${state.pageSize}`);
      }
    }
    if (state.category !== "") {      
      getProducts();
    }
    
  }, [state.category,state.dealType,state.department,state.subdepartment, state.page, state.pageSize, state.selectedMenuItem]);

  const pageChanged = (page: number, pageSize: number) => {
    actions.setPage(page);
    actions.setPageSize(pageSize);

    const department = state.department === "" || state.department == undefined ? "" : "/"+ state.department
    const subdepartment = state.subdepartment === "" || state.subdepartment == undefined ? "" : "/"+state.subdepartment
    navigate(`/${Pages.Home}/${state.category}${department}${subdepartment}/${page}/${pageSize}`);
    const section = document.querySelector("#productGrid");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getProducts = async () => {
    actions.setProductsLoading(true);

    const NewPageSettings: PageSettings = {
      Category: state.category ?? Categories.All,
      Department: state.department,
      Subdepartment: state.subdepartment,
      PageNumber: state.page ?? 1,
      PageSize: state.pageSize ?? 30,
      SearchedData: state.searchedData,
      ChosenSearchOption: state.chosenSearchOption.value,
      DealsType: state.dealType ?? DealTypes.NewestDeals,
    };

    await api
      .getProductsByPage(NewPageSettings)
      .then((res) => {
        actions.setProducts(res.item1);
        actions.setTotalProducts(res.item2);
      });
      actions.setProductsLoading(false);
  };

  const isMobile = useMediaQuery({ maxWidth: 669 });

  const handleChangeDealType = (value: string) => {
    actions.setDealType(value);
  };
  
  return (
    <>
      <Spin spinning={state.productsLoading}>
      <MediaQuery minWidth={670}>
      <div style={{ marginTop: '10px', marginLeft:'25px', display:"flex", flexDirection:"row"}}>
        <div style={{display:"flex", alignItems: "center", fontSize:"30px", fontWeight:"700"}}>Sort by :</div>
        <Select
          value={state.dealType}
          style={{ width: '265px', marginLeft: '10px' }}         
          onChange={handleChangeDealType}
          options={[
            { value: DealTypes.NewestDeals, label: DealTypes.NewestDeals },
            { value: DealTypes.BestSellers, label: DealTypes.BestSellers },
            { value: DealTypes.LowestPrices, label: DealTypes.LowestPrices }
          ]}
          className="title-select"
          dropdownStyle={{fontSize: "20px"}}
        />
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={669}>
      <div style={{ marginTop: '10px', marginLeft:'25px', display:"flex", flexDirection:"row", justifyContent:"center"}}>
        <div style={{display:"flex", alignItems: "center", fontSize:"20px", fontWeight:"700", paddingRight:"5px"}}>Sort by :</div>
          <Select
            defaultValue={DealTypes.NewestDeals}
            style={{ width: '190px' }}
            onChange={handleChangeDealType}
            options={[
              { value: DealTypes.NewestDeals, label: DealTypes.NewestDeals },
              { value: DealTypes.BestSellers, label: DealTypes.BestSellers },
              { value: DealTypes.LowestPrices, label: DealTypes.LowestPrices }
            ]}
            className="title-select title-select-mobile"
            
          />
        </div>
      </MediaQuery>
        <Row 
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
          gutter={[16, 16]} 
          id="productGrid"
        >
          {state.products.map((product: ProductDto) => (
            <Col key={product.id}>
              <div className="divStyles">
              <ProductCard
                product={product}
              />
              </div>
            </Col>
          ))}
        </Row>
        <Row justify='center' style={{marginTop: '50px'}}>
          <Pagination
            responsive
            current={state.page}
            defaultCurrent={1}
            defaultPageSize={30}
            total={state.totalProducts}
            hideOnSinglePage
            onChange={pageChanged}
            showSizeChanger
          />
        </Row>
      </Spin>
    </>
  );
};

export default Products;
