import { Layout } from "antd";
import { Header, Content, Footer } from "antd/es/layout/layout";
import HeaderFilling from "../../header/headerFilling";
import AboutPageFilling from "./aboutPageFilling";
import "../../header/header.css";
import FooterFilling from "../../footter/footerFilling";
import MenuDrawer from "../../menu/menuDrawer";
import React from "react";

const AboutPage = () => {
    return (
      <>
        <Layout>
          <Header className="header">
            <HeaderFilling />
          </Header>
          <Content style={{ padding: "0 50px", background: 'white' }}>
            <AboutPageFilling/>
          </Content>
          <Footer style={{background:'white'}}>
            <FooterFilling/> 
          </Footer>
          <MenuDrawer/>
        </Layout>
      </>
    );
  };
  
  export default AboutPage;