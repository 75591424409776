import { FacebookOutlined, InstagramOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useEffect } from "react";
import MediaQuery from "react-responsive";
import SocialMediaLinks from "../../../constants/socialMediaLinks";
import './aboutPageFilling.css';
import TelegramLogo from "../../../logos/telegLogo.svg"

const AboutPageFilling = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
      
    return (
      <>
      <MediaQuery minWidth={810}>
        <Row justify="center" style={{margin: '100px 0px 90px 0'}}>
            <Col><div className="about-title text-color-align-line-height">About</div></Col>
        </Row>
        <Row justify="center">
            <Col span={19}>
                <div className="description-text text-color-align-line-height">
                Welcome to EOM Daily Deals, your go-to website for finding amazing deals on a variety of merchandise. Our website is designed to bring you the best deals and discounts on a daily basis, so you can save money while shopping for the things you need and want.
At EOM Daily Deals, we believe that everyone should have access to high-quality merchandise at affordable prices. That's why we work hard to bring you deeply discounted products from a wide range of categories, including electronics, fashion, home goods, and more.
Our team is dedicated to finding the best deals available, and we only feature products from reputable sellers. We also provide detailed product descriptions and images, so you can make informed purchasing decisions.
Thank you for choosing EOM Daily Deals for your online shopping needs. We hope you enjoy browsing our selection of deeply discounted merchandise and find something you love.
                </div>
            </Col>
        </Row>
        <Row justify="center" style={{margin: '80px 0 80px 0'}}>
            <Col>
                <div className="join-us-title text-color-align-line-height">
                    JOIN US
                </div>    
            </Col>
        </Row>
        <Row justify="center" gutter={32} style={{margin: '0px 0px 50px 0px'}}>
            <Col span={6}>  
            <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="aboutPage-social-media-button text-color-align-line-height" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}<span style={{marginInlineStart: "8px"}}>Telegram</span>
                  </Button> 
                </a>   
            </Col>
            <Col span={6}>
                <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook"> 
                    <Button className="aboutPage-social-media-button text-color-align-line-height" icon={<FacebookOutlined className="socialNetworksIcon-AboutPage"/>}>
                        Facebook
                    </Button>
                </a>
            </Col>
            <Col span={6}>
            <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
              <Button className="aboutPage-social-media-button text-color-align-line-height" icon={<InstagramOutlined className="socialNetworksIcon-AboutPage"/>}>
                Instagram
              </Button>
            </a>
            </Col>
        </Row>  
        </MediaQuery> 
        <MediaQuery maxWidth={809}>
        <Row justify="center" style={{margin: '100px 0px 90px 0px'}}>
            <Col><div className="about-title text-color-align-line-height">About</div></Col>
        </Row>
        <Row justify="center">
            <Col span={19}>
                <div className="description-text text-color-align-line-height">
                Welcome to EOM Daily Deals, your go-to website for finding amazing deals on a variety of merchandise. Our website is designed to bring you the best deals and discounts on a daily basis, so you can save money while shopping for the things you need and want.
At EOM Daily Deals, we believe that everyone should have access to high-quality merchandise at affordable prices. That's why we work hard to bring you deeply discounted products from a wide range of categories, including electronics, fashion, home goods, and more.
Our team is dedicated to finding the best deals available, and we only feature products from reputable sellers. We also provide detailed product descriptions and images, so you can make informed purchasing decisions.
Thank you for choosing EOM Daily Deals for your online shopping needs. We hope you enjoy browsing our selection of deeply discounted merchandise and find something you love.
                </div>
            </Col>
        </Row>
        <Row justify="center" style={{margin: '80px 0 80px 0'}}>
            <Col>
                <div className="join-us-title text-color-align-line-height">
                    JOIN US
                </div>    
            </Col>
        </Row>
        <Row justify="center" style={{margin: '0px 0px 50px 0px', display: "flex", flexDirection: "column"}}>    
            <Button className="aboutPage-social-media-button text-color-align-line-height" >
                {<img src={TelegramLogo} alt="" className="telegramLogo"/>} <span style={{marginInlineStart: "8px"}}>Telegram</span>
            </Button> 
            <Button className="aboutPage-social-media-button text-color-align-line-height" icon={<FacebookOutlined className="socialNetworksIcon-AboutPage"/>}>
                Facebook
            </Button>
            <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
              <Button className="aboutPage-social-media-button text-color-align-line-height" icon={<InstagramOutlined className="socialNetworksIcon-AboutPage"/>}>
                Instagram
              </Button>
            </a>
        </Row>    
        </MediaQuery> 
      </>
    );
  };
  
  export default AboutPageFilling;