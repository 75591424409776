import { Layout } from 'antd';
import { useEffect } from 'react';
import EmailButton from './body/emailButton';
import ScrollToTopButton from './body/scrollToTopButton';
import FooterFilling from './footter/footerFilling';
import "./header/header.css";
import HeaderFilling from './header/headerFilling';
import MenuDrawer from './menu/menuDrawer';
import Advertising from './pages/home/advertising/advertising';
import Products from "./pages/home/products/products";

const { Header, Content, Footer } = Layout;

const Main = () => {
  //let api = new ApiService();
  
  useEffect(() => {
    window.scroll(0, 0);

    // const subcategories: Subcategories = {
    //   Department: (Object.values(Department) as (keyof typeof Department)[]),
    //   Subdepartment: (Object.values(Subdepartment) as (keyof typeof Subdepartment)[])
    // }
    // console.log(subcategories)
    // api.setUpSubcategories(JSON.stringify(subcategories))
  }, []);
  
  return (
    <Layout>
      <Header className='header'>  
        <HeaderFilling/> 
      </Header> 

      <Content style={{ paddingBottom: "50px", background: "white"}}>
        <Advertising/>
        <Products />
      </Content>

      <ScrollToTopButton/>
      <EmailButton/>   
        
      <Footer style={{background:'white'}}>
        <FooterFilling/> 
      </Footer>
      
      <MenuDrawer/>
    </Layout>
  );
};

export default Main;
