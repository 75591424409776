import { MailOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useTable } from '../../stores/Store';
import "./scrollToTopButton.css";

const EmailButton: React.FC = () => {
    const [showButton, setShowButton] = useState(false);
    const [state, actions] = useTable();

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        setShowButton(scrollPosition > 0);
      };
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


  
    return (
      <>
        <MediaQuery minWidth={650}>
          <button
            className={`email-button-base scroll-to-top-button-pc ${showButton ? "visible" : ""}`}
            onClick={() => actions.setVisibleSubscribeModal(true)}
          >
            <div style={{display: "flex", flexDirection:"column"}}>
            <MailOutlined className="email-icon" /> <span style={{color:"rgb(63, 96, 121)"}}>Join Us</span>
            </div>
          </button>
        </MediaQuery>
        <MediaQuery minWidth={0} maxWidth={649}>
          <button
            className={`email-button-base scroll-to-top-button-mobile ${showButton ? "visible" : ""}`}
            onClick={() => actions.setVisibleSubscribeModal(true)}
          >
            <div style={{display: "flex", flexDirection:"column"}}>
            <MailOutlined className="email-icon" /><span style={{color:"rgb(63, 96, 121)", fontSize:'10px'}}>Join Us</span>
            </div>
          </button>
        </MediaQuery>
      </>
    );
  };
  
  export default EmailButton;
