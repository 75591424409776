import { Layout } from "antd";
import { Header, Content, Footer } from "antd/es/layout/layout";
import FooterFilling from "../../footter/footerFilling";
import HeaderFilling from "../../header/headerFilling";
import MenuDrawer from "../../menu/menuDrawer";
import ProductsCriteriaPageFilling from "./productsCriteriaPageFilling";


const ProductsCriteriaPage = () => {
  return (
    <>
      <Layout>
        <Header className="header">
          <HeaderFilling />
        </Header>
        <Content style={{ padding: "0 50px", background: 'white' }}>
          <ProductsCriteriaPageFilling/>
        </Content>
        <Footer style={{background:'white'}}>
          <FooterFilling/> 
        </Footer>
        <MenuDrawer/>
      </Layout>
    </>
  );
  };

  export default ProductsCriteriaPage;