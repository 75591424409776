import { Col, Row } from "antd";
import MediaQuery from "react-responsive";
import FileUploader from "./fileUploader";

const UploadFilesPage = () => {
  return (
    <>
      <MediaQuery minWidth={810}>
      <Row justify="center" style={{margin: '120px 0px'}}>
        <Col><div className="about-title text-color-align-line-height">Upload excel file with desired ASINs</div></Col>
      </Row>
      <FileUploader/>
      </MediaQuery>
      <MediaQuery maxWidth={809}>
      <Row justify="center" style={{margin: '75px 0px 75px 0px'}}>
        <Col><div className="about-title text-color-align-line-height">Upload excel file with desired ASINs</div></Col>
      </Row>
      <FileUploader/>
      </MediaQuery>
    </>
  );
};

export default UploadFilesPage;