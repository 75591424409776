import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./components/main";
import AboutPage from "./components/pages/about/aboutPage";
import BlockedProductsPage from "./components/pages/blockedProducts/blockedProductsPage";
import ProductPage from "./components/pages/home/products/productPage";
import Login from "./components/pages/login/login";
import ProductDesignerPage from "./components/pages/productDesigner/productDesignerPage";
import ProductsCriteriaPage from "./components/pages/productsCriteria/productsCriteriaPage";
import UploadFilesPage from "./components/pages/uploadFiles/uploadFilesPage";

const App: FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/home/:category/:pageNumber/:pageSize" element={<Main/>}/>
        <Route path="/home/:category/:department/:pageNumber/:pageSize" element={<Main/>}/>
        <Route path="/home/:category/:department/:subdepartment/:pageNumber/:pageSize" element={<Main/>}/>
        <Route path="/product/:productId" element={<ProductPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/uploadFiles" element={<UploadFilesPage/>}/>
        <Route path="/blockedProducts" element={<BlockedProductsPage/>}/>
        <Route path="/productDesigner" element={<ProductDesignerPage/>}/>
        <Route path="/productsCriteria" element={<ProductsCriteriaPage/>}/>
        <Route path="/contact" element={<AboutPage/>}/>
        <Route path="/superAdmin" element={<Login/>}/>
      </Routes>
    </> 
  );
}

export default App;
