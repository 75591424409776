import {
  ArrowDownOutlined,
  FacebookOutlined,
  InstagramOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Popconfirm,
  Rate,
  Row,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { stat } from "fs";
import { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import Pages from "../../../../constants/pages";
import SocialMediaLinks from "../../../../constants/socialMediaLinks";
import TelegramLogo from "../../../../logos/telegLogo.svg";
import { ProductDto } from "../../../../models/ProductDto";
import { ProductUpdateModel } from "../../../../models/ProductUpdateModel";
import { RemovedIdAmzLinkDto } from "../../../../models/RemovedIdAmzLinkDto";
import ApiService from "../../../../services/Api/api-service";
import AuthorizeService from "../../../../services/Api/autorise-service";
import { useTable } from "../../../../stores/Store";
import notificationLogic from "../../../notifiacation/notifiacation";
import "./productCardPage.css";

const ProductCardPage = ({ productId }: { productId: number }) => {
  let navigate = useNavigate();

  const [state, actions] = useTable();
  const [selectedProduct, setSelectedProduct] = useState<ProductDto>();
  const [removedIdAmzLink, setRemovedIdAmzLink] = useState<RemovedIdAmzLinkDto>();
  const [findedProduct, setFindedProduct] = useState<boolean>(true);
  
  const [form] = Form.useForm();

  let api = new ApiService();
  const signedIn = AuthorizeService.isSignedIn();

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  async function getProduct () {
    let selectedProduct: any = await api.getProductById(Number.parseInt(productId.toString()));
    if(selectedProduct.success !== false) {
      form.setFieldsValue({
        productname: selectedProduct?.name,
        productDescription: selectedProduct?.description,
        refferallink: selectedProduct?.productLinks.referralUrl,
      });
      setSelectedProduct(selectedProduct);
      setFindedProduct(true);
    } else {   
      setRemovedIdAmzLink(await api.getRemovedIdAmzLinkById(Number.parseInt(productId.toString())))                           
      setFindedProduct(false);    
    }
  };

  async function changeProduct() {
    let newProduct: ProductUpdateModel = {
      id: selectedProduct!.id,
      name: form.getFieldValue("productname"),
      description: form.getFieldValue("productDescription"),
      refferalLink: form.getFieldValue("refferallink"),
    };

    const response = await api.updateProduct(newProduct);
    if (response.success) {
      notificationLogic("success", "Successfully updated");
      getProduct();
    } else {
      notificationLogic('error', response.errorMessage);
    }
  }

  const deleteConfirm = async () => {
    const response = await api.deleteProduct(selectedProduct!.id);
    if (response.success) {
      notificationLogic('success', `Product with Id: ${selectedProduct!.id} successfully deleted`);
      const path = `/${Pages.Home}/${state.category}/1/${state.pageSize}`;
      navigate(path);
    } else {
      notificationLogic('error', response.errorMessage);
    }
  };

  const blockConfirm = async () => {
    const response = await api.blockProduct(selectedProduct!.id);
    if (response.success) {
      notificationLogic('success', `Product with Id: ${selectedProduct!.id} was successfully blocked`);
      const path = `/${Pages.Home}/${state.category}/1/${state.pageSize}`;
      navigate(path);
    } else {
      notificationLogic('error', response.errorMessage);
    }
  }

  const buyClicked = () => {    
    let link = selectedProduct?.amazonLink.includes("amazon") ? selectedProduct?.amazonLink + "?tag=eomdailydeals-20" : selectedProduct?.amazonLink
    window.open(link);
  };

  const changeForCarouselStatus = async (status: boolean, event: any) => {
    event.preventDefault(); 
    await api
      .setProductForCarouselStatus(productId, status).then(async (response) =>{
        if (response.success) {
          if(status){
            notificationLogic('success', `Product add to carousel!`);
          }
          else{
            notificationLogic('success', `Product remove from carousel!`);
          }              
        } else {
          notificationLogic('error', response.errorMessage);
        }       
      });    

      await api
          .getCarouselProductByCategory(state.category)
          .then((res) => {
            actions.setCarouselProducts(res)
          });
  };

  const newspaperSpinning = [
    { animation: "4s ease-in-out 0s infinite alternate" },
    { background: "#a7dcf2" },
  ];

  const newspaperTiming = {
    duration: 3000,
    iterations: 2,
  };

  const disclosureClicked = () => {
    const section = document.getElementById("Disclosure");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
    section?.animate(newspaperSpinning, newspaperTiming);
  };

  return (
    <>
      {findedProduct && signedIn && (
        <>
          <Divider className=" cardLabelText divider">
            Admin settings
          </Divider>
          <Row gutter={12} align="middle" justify="space-around">
            <Form
              name="basic"
              form={form}
              labelAlign="left"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              style={{
                width: "1000px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              onFinish={changeProduct}
            >
              <Form.Item
                className="cardLabelText"
                label="Product Name"
                name="productname"
                required
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="cardLabelText"
                label="Product Description"
                name="productDescription"
                initialValue={selectedProduct?.description}
                required
                rules={[{ required: true, message: "Please input description!" }]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item
                colon={false}
                className="cardLabelText"
                label="Refferal Link"
                name="refferallink"
                initialValue={selectedProduct?.productLinks.referralUrl}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="cardLabelText"
                label="Amazon Link"
                name="amazonlink"
              >
                <a href={selectedProduct?.amazonLink}>{selectedProduct?.amazonLink}</a>
              </Form.Item>
              <Row style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>                
                <Col>
                  <Form.Item>
                    <Popconfirm
                      placement="top"
                      title="Are you sure to delete this product?"
                      description="This product will be deleted from the site"
                      onConfirm={deleteConfirm}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger>
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Popconfirm
                      placement="top"
                      title="Are you sure to block this product?"
                      description="This product will be blocked for the site"
                      onConfirm={blockConfirm}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger>
                        Block
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Col>
                <Col>
                  {state.carouselProducts.some((element) => element.id === productId) ?                     
                    <Button danger onClick={(event: any) => changeForCarouselStatus(false, event)}>
                      Remove from carousel
                    </Button>
                    :                     
                    <Button type="primary" htmlType="submit" onClick={(event) => changeForCarouselStatus(true, event)}>
                      Add to carousel
                    </Button>
                  }   
                </Col> 
              </Row>              
            </Form>
          </Row>          
          <Divider />
        </>
      )}
      {findedProduct ? (<Card className="productCardPage">
        <div className="cardContainer">
          <Row gutter={[16, 16]} justify={"space-around"}>
            <MediaQuery minWidth={769}>
              <Col
                span={12}
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
              <div className="imageSettingsDiv">
                <Image src={selectedProduct?.imageUrl} alt={""} className="imageSettings"/>
              </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div className="cardInfo">
                  <Row className="cardLabelText">{selectedProduct?.name}</Row>
                  <Row className="cardDescriptionText">
                    {selectedProduct?.description}
                  </Row>
                </div>
                <div className="cardPrice">
                  <div className="cardPriceColumns">
                    <div className="cardPriceInline">
                      <Row className="cardLabelOldPriceText">
                        $ {selectedProduct?.averagePrice.toFixed(2)}
                      </Row>
                      <Row className="cardLabelText">
                        $ {selectedProduct?.buy.toFixed(2)}
                      </Row>
                    </div>
                    <div className="cardPriceInline">
                      {selectedProduct?.buy && selectedProduct.averagePrice && (
                        <Row align={"middle"} className="cardLabelText">
                          <ArrowDownOutlined />
                          {(
                            100 -
                            (selectedProduct!.buy * 100) / selectedProduct!.averagePrice
                          ).toFixed(0)}{" "}
                          % off
                        </Row>
                      )}
                    </div>
                  </div>
                  <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:'center'}}>
                    <div>
                      {selectedProduct?.rating !== undefined && selectedProduct!.rating > 0 && (
                        <Rate disabled allowHalf value={selectedProduct!.rating / 10} />
                      )}
                    </div>
                    <div>
                      <span>Last Update: {selectedProduct?.lastUpdate}. Deal found on Amazon</span>
                    </div>
                  </div>
                </div>
                <Button onClick={buyClicked} className="cardBuyButton">
                  Buy now
                </Button>
                <Row>
                  We may earn a small commission when you click the links on
                  this page.{" "}
                  <a onClick={disclosureClicked} style={{ padding: "0 10px" }}>
                    {" "}
                    Click here{" "}
                  </a>{" "}
                  to learn more. We appreciate your support!
                </Row>
              </Col>
              <div className="followUsContainer">
                <Col
                  span={12}
                  offset={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Row className="followUs">Follow us</Row>
                  <Row style={{ display: "flex", flexWrap: "nowrap", margin: '0px 0px 25px 0px' }}>
                    <div style={{ marginLeft: '50px' }}>
                      <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
                        <Button className="product-page-social-media-button">
                          <InstagramOutlined className="cardSocialNetworksIcon" />
                        </Button>
                      </a>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                    <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="productCardPage-telegram-logo-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                      <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
                        <Button className="product-page-social-media-button">
                          <FacebookOutlined className="cardSocialNetworksIcon" />
                        </Button>
                      </a>
                    </div>
                  </Row>
                </Col>
              </div>
            </MediaQuery>
            <MediaQuery minWidth={0} maxWidth={768}>
              <Row>
                <Image
                  src={selectedProduct?.imageUrl}
                  style={{ objectFit: "cover" }}
                  alt={""}
                />
              </Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div className="cardInfo">
                  <Row className="cardLabelText">{selectedProduct?.name}</Row>
                  <Row className="cardDescriptionText">
                    {selectedProduct?.description}
                  </Row>
                </div>
                <div className="cardPrice">
                  <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <div className="cardPriceInline">
                      <Row className="cardLabelOldPriceText">
                        ${selectedProduct?.averagePrice.toFixed(2)}
                      </Row>
                      <Row className="cardLabelText">
                        $ {selectedProduct?.buy.toFixed(2)}
                      </Row> 
                    </div>               
                    <div className="cardPriceInline">
                      {selectedProduct?.buy && selectedProduct.averagePrice && (
                        <Row align={"middle"} className="cardLabelText">
                          <ArrowDownOutlined />
                          {(
                            100 -
                            (selectedProduct!.buy * 100) / selectedProduct!.averagePrice
                          ).toFixed(0)}{" "}
                          % off
                        </Row>
                      )}
                    </div>
                    <div>
                      {selectedProduct?.rating !== undefined && selectedProduct!.rating > 0 && (
                        <Rate disabled allowHalf value={selectedProduct!.rating / 10} />
                      )}
                    </div>
                    <div>
                      <span>Last Update: {selectedProduct?.lastUpdate}. Deal found on Amazon</span>
                    </div>
                  </div>
                </div>
                {/* <div style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
                    <div>
                      {selectedProduct?.rating !== undefined && selectedProduct!.rating > 0 && (
                        <Rate disabled allowHalf value={selectedProduct!.rating / 10} />
                      )}
                    </div>
                    <div>
                      <span>Last Update: {selectedProduct?.lastUpdate}. Deal found on Amazon</span>
                    </div>
                </div> */}
                <Button onClick={buyClicked} className="cardBuyButton">Buy now</Button>
                <Row>
                  We may earn a small commission when you click the links on
                  this page.{" "}
                  <a onClick={disclosureClicked} style={{ padding: "0 10px" }}>
                    {" "}
                    Click here{" "}
                  </a>{" "}
                  to learn more. We appreciate your support!
                </Row>
              </Col>
              <div className="followUsContainerTablet">
                <Row className="followUs">Follow us</Row>
                <Row style={{ display: "flex", flexWrap: "nowrap", margin: '0px 0px 30px 0px' }}>
                  <a href={SocialMediaLinks.InstagramLink} target="_blank" rel="instagram">
                    <Button
                      className="productPage-social-media-button productPage-social-media-mobile" icon={<InstagramOutlined className="cardSocialNetworksIcon" />}
                    />
                  </a>
                  <a href={SocialMediaLinks.TelegramLink} target="_blank" rel="telegram">
                  <Button className="productCardPage-telegram-logo-button" >
                    {<img src={TelegramLogo} alt="" className="telegramLogo"/>}  
                  </Button> 
                </a>
                  <a href={SocialMediaLinks.FacebookLink} target="_blank" rel="facebook">
                    <Button
                      className="productPage-social-media-button productPage-social-media-mobile" icon={<FacebookOutlined className="cardSocialNetworksIcon" />}
                    />
                  </a>
                </Row>
              </div>
            </MediaQuery>
          </Row>
        </div>
      </Card>
      ) : (
        ((removedIdAmzLink?.productId === 0)
          ? 
          (<div style={{marginTop: "158px", display: "flex", alignItems: "center", flexDirection: "column"}}>
            <h1>Oops!</h1>
            <h3>This Offer is No Longer Available</h3>
           </div>) 
          :
          (<div style={{marginTop: "158px", display: "flex", alignItems: "center", flexDirection: "column"}}>
          <h1>Oops!</h1>
          <h3>This Offer is No Longer Available</h3>
          <h3>If you still like to view the product follow the link:</h3>
          <a href={removedIdAmzLink?.amazonLink+"?tag=eomdailydeals-20"}>{removedIdAmzLink?.amazonLink+"?tag=eomdailydeals-20"}</a>
          </div>)))
        }
    </>
  );
};

export default ProductCardPage;
