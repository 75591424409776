import { Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import FooterFilling from "../../footter/footerFilling";
import HeaderFilling from "../../header/headerFilling";
import MenuDrawer from "../../menu/menuDrawer";
import UploadFilesPageFilling from "./uploadFilesPageFilling";

const UploadFilesPage = () => {
  return (
    <>
      <Layout>
        <Header className="header">
          <HeaderFilling />
        </Header>
        <Content style={{ padding: "0 50px", background: 'white' }}>
          <UploadFilesPageFilling/>
        </Content>
        <Footer style={{background:'white'}}>
          <FooterFilling/> 
        </Footer>
        <MenuDrawer/>
      </Layout>
    </>
  );
};

export default UploadFilesPage;


