import { AppstoreOutlined, BarChartOutlined, HomeOutlined, LockOutlined, LogoutOutlined, PlusCircleOutlined, RocketOutlined, ToolOutlined } from "@ant-design/icons";
import { Drawer, Menu, MenuProps, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Categories from "../../constants/categories";
import DealTypes from "../../constants/dealTypes";
import Department from "../../constants/department";
import MenuItems from "../../constants/menuItems";
import Pages from "../../constants/pages";
import Subdepartment from "../../constants/subdepartment";
import AuthorizeService from "../../services/Api/autorise-service";
import { useTable } from "../../stores/Store";
import "../menu/menu.css";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const MenuDrawer = () => {
  const [state, actions] = useTable();
  const navigate = useNavigate();

  const authorizeService = new AuthorizeService();
  const [screenSize, getDimension] = useState({ dynamicWidth: window.innerWidth });

  const [mode, setMode] = useState<'vertical' | 'inline'>('inline');
  const [items, setMenuItems] = useState<MenuItem[]>([
    getItem(MenuItems.Categories, MenuItems.Categories, <AppstoreOutlined />, (Object.values(Categories) as (keyof typeof Categories)[]).map(category => getItem(category, category))),
  ]);

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    if (screenSize.dynamicWidth < 1249) {

      let newMenu = [
        getItem(MenuItems.Home, MenuItems.Home, <HomeOutlined />),
        getItem(MenuItems.About, MenuItems.About, <RocketOutlined />),
        getItem(MenuItems.Categories, MenuItems.Categories, <AppstoreOutlined />, 
          (Object.values(Categories) as (keyof typeof Categories)[]).map(category => {
            if(category === Categories.ClothingShoesAndJewelry.toString()){
              return getItem(category, category, null, (Object.values(Department) as (keyof typeof Department)[]).map(department => {
                if(department === Department.Men || department === Department.Women){
                  return getItem(department, department, null, (Object.values(Subdepartment) as (keyof typeof Subdepartment)[]).map(subdepartment => getItem(subdepartment, subdepartment)))
                }
                else{
                  return getItem(department, department) 
                }
              }))
            }
            else{
              return getItem(category, category)
            }
          }))
      ];

      if (state.signedIn) {
        newMenu.push(getItem(MenuItems.UploadFiles, MenuItems.UploadFiles, <PlusCircleOutlined />))
        newMenu.push(getItem(MenuItems.BlockedProducts, MenuItems.BlockedProducts, <LockOutlined />))
        newMenu.push(getItem(MenuItems.ProductDesigner, MenuItems.ProductDesigner, <ToolOutlined />))
        newMenu.push(getItem(MenuItems.ProductsCriteria, MenuItems.ProductsCriteria, <BarChartOutlined />))
        if (screenSize.dynamicWidth < 449) {
          newMenu.push(getItem(MenuItems.LogOut, MenuItems.LogOut, <LogoutOutlined />))
        }
      }

      setMenuItems(newMenu)
    }
    else {
      const newMenuItems = [
        getItem(MenuItems.Categories, MenuItems.Categories, <AppstoreOutlined />, 
          (Object.values(Categories) as (keyof typeof Categories)[]).map(category => {
            if(category === Categories.ClothingShoesAndJewelry.toString()){
              return getItem(category, category, null, (Object.values(Department) as (keyof typeof Department)[]).map(department => {
                if(department === Department.Men || department === Department.Women){
                  return getItem(department, department, null, (Object.values(Subdepartment) as (keyof typeof Subdepartment)[]).map(subdepartment => getItem(subdepartment, subdepartment)))
                }
                else{
                  return getItem(department, department) 
                }
              }))
            }
            else{
              return getItem(category, category)
            }
          }))
      ];
  
      if (state.signedIn) {
        newMenuItems.push(getItem(MenuItems.UploadFiles, MenuItems.UploadFiles, <PlusCircleOutlined />));
        newMenuItems.push(getItem(MenuItems.BlockedProducts, MenuItems.BlockedProducts, <LockOutlined />));
        newMenuItems.push(getItem(MenuItems.ProductDesigner, MenuItems.ProductDesigner, <ToolOutlined />));
        newMenuItems.push(getItem(MenuItems.ProductsCriteria, MenuItems.ProductsCriteria, <BarChartOutlined />));
      }
  
      setMenuItems(newMenuItems);
    }
    return (() => { window.removeEventListener('resize', setDimension) })
  }, [screenSize, state.signedIn])

  const LogOutClick = () => {
    actions.setShowMenu(false)
    authorizeService.logout();
    actions.setSignedIn(AuthorizeService.isSignedIn());
  };

  const setDimension = () => getDimension({dynamicWidth: window.innerWidth})

  const navigateToHome = () => {
    actions.setShowMenu(false)
    actions.setCategory(Categories.All)
    actions.setDepartment("")
    actions.setSubdepartment("")
    actions.setChosenSearchOption({ value: Categories.All, label: Categories.All })
    actions.setPage(1)
    actions.setSearchedData("")
    actions.setDealType(DealTypes.NewestDeals)
    actions.setPageSize(30)
    navigate(`/${Pages.Home}/${Categories.All}}/1/30`);
  }

  const choosingCategories = (menuOption: any) => {
    actions.setShowMenu(false)
    actions.setPage(1);
    const category = menuOption.keyPath[1].split(/[ ,]+/)[0]
    actions.setCategory(category);
    actions.setDepartment(menuOption.keyPath[2]);
    actions.setSubdepartment(menuOption.keyPath[3]);
    actions.setSelectedMenuItem(menuOption.key);
    actions.setSearchedData("");
    actions.setDealType(DealTypes.NewestDeals);
    const department = menuOption.keyPath[2] === "" || menuOption.keyPath[2] == undefined ? "" : "/"+menuOption.keyPath[2]
    const subdepartment = menuOption.keyPath[3] === "" || menuOption.keyPath[3] == undefined ? "" : "/"+menuOption.keyPath[3]
    navigate(`/${Pages.Home}/${category}${department}${subdepartment}/1/${state.pageSize}`);
  }

  const onClick = (menuOption: any) => {
    menuOption.keyPath = menuOption.keyPath.reverse();
    if (MenuItems.Categories === menuOption.keyPath[0]) {
      choosingCategories(menuOption)
    }
    else if (MenuItems.Home === menuOption.keyPath[0]) {
      navigateToHome()
    }
    else if (MenuItems.About === menuOption.keyPath[0]) {
      actions.setShowMenu(false)
      navigate(`/${Pages.About}`);
    }
    else if (MenuItems.UploadFiles === menuOption.keyPath[0]) {
      actions.setShowMenu(false)
      navigate(`/${Pages.UploadFiles}`);
    }
    else if (MenuItems.BlockedProducts === menuOption.keyPath[0]) {
      actions.setShowMenu(false)
      navigate(`/${Pages.BlockedProducts}`);
    }
    else if (MenuItems.ProductDesigner === menuOption.keyPath[0]) {
      actions.setShowMenu(false)
      navigate(`/${Pages.ProductDesigner}`);
    }
    else if (MenuItems.ProductsCriteria === menuOption.keyPath[0]) {
      actions.setShowMenu(false)
      navigate(`/${Pages.ProductsCriteria}`);
    }
    else if (MenuItems.LogOut === menuOption.keyPath[0]) {
      LogOutClick();
    }
  }

  return (
    <Drawer
      placement="left"
      onClose={() => actions.setShowMenu(false)}
      open={state.showMenu}
      width={'350px'}
    >
      <Switch onChange={(value) => setMode(value ? 'vertical' : 'inline')} />
      <Menu
        className="menu"
        selectedKeys={[state.selectedMenuItem]}
        mode={mode}
        theme={'light'}
        items={items}
        onClick={onClick}
      />
    </Drawer>
  );
};
export default MenuDrawer;